<template>
    <layout>
        <div class="dashboard">
            <v-row class="top mb-4 d-none d-lg-flex">
                <v-col cols="12" lg="7" class="px-10">
                    <v-text-field class="trackshipping" placeholder="Track shipping" append-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="12" lg="5" class="text-right pr-10">
                    <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" :to="{name: 'account.quote'}">Start new quote</v-btn>
                </v-col>
            </v-row>
            <v-row class="main mt-5">
                <v-col cols="12" lg="7" class="px-md-10">
                    <div class="d-flex justify-space-between align-center bg-transparent">
                        <h2 class="heading-2">Recent quotes</h2>
                        <router-link :to="{ name: 'account.quotes', params: {} }" class="d-none d-md-block">View all quotes</router-link>
                        <router-link :to="{ name: 'account.quotes', params: {} }" class="d-block d-md-none">View all</router-link>
                    </div>
                    <v-card v-for="(load, index) in quotes" :key="index" class="px-7 py-4 mt-7" flat>
                        <v-card-text class="pa-0">
                            <div class="top d-flex justify-space-between align-center pb-4 mb-5">
                                <span v-if="dateCompare(dateFormat(load.updated_at, 3), dateFormat(), '<')" class="badge py-2 px-4 expired">Expired</span>
                                <span v-else class="badge py-2 px-4 active">Active</span>
                                <div class="date">
                                    Quoted on {{dateFormat(load.updated_at)}}
                                </div>
                            </div>
                            <div class="content">
                                <div class="text-18-75">
                                    {{ getCity(load) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(load, 'to') }}
                                </div>
                                <div class="d-flex align-end">
                                    <div class="d-flex w-100 justify-space-between mt-4 flex-column">
                                        <div v-for="item in filterItems(load)">
                                            <div class="text-14-60 mb-1">
                                                {{ item.description }}
                                            </div>
                                            <div class="text-14 mb-3">
                                                {{ item.quantity }} Bundles, {{ item.quantity * item.weight }} pounds
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="text-right mb-1">
                                            Rates starting at
                                        </div>
                                        <div class="text-18-75 text-right mb-4">
                                            $14.15
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mt-4 align-end">
                                    <div class="">
                                        <div class="text-14-60">
                                            Ship Date
                                        </div>
                                        <div class="">
                                            {{dateFormat(load.items[0].date)}}
                                        </div>
                                    </div>
                                    <div class="">
                                        <v-btn x-large class="secondary px-8 py-4 text-14 text-transform-none" depressed :to="{name: 'account.quote', params: {id: load.id}}">Resume quote</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="5" class="pr-10">
                    <h2 class="heading-2">Recent orders</h2>
                    <v-card v-if="Object.keys(this.orders).length = 0" class="px-7 py-4 mt-7" flat>
                        <div class="d-flex flex-column align-center my-8">
                            <img src="@/assets/icons/account/box.svg" alt="">
                            <p class="text-16" style="color: #808080">Once you book a shipment, <br/>it will appear here for easy tracking</p>
                        </div>
                    </v-card>
                    <card-order :orders="orders"/>
                    <!-- <v-card class="px-7 py-4 mt-7" flat>
                        <v-card-text class="pa-0">
                            <div class="top d-flex flex-column pb-4 mb-5">
                                <div class="mb-5">
                                    <span class="badge py-2 px-4 shipped mr-5">Shipped</span>
                                    <img src="@/assets/icons/svg/shipping/airplane-dark.svg" alt="">
                                </div>
                                <div class="text-20-75 mb-3">
                                    #83933947652
                                </div>
                                <router-link :to="{ name: '', params: {} }" class="mb-1">Track shipping</router-link>
                                <div class="text-14">
                                    Ship date: 08/23/2020
                                </div>
                            </div>
                            <div class="content">
                                <div class="text-16">
                                    Naples, FL <v-icon>mdi-chevron-right</v-icon> Lecanto, FL
                                </div>
                                <div class="mt-4">
                                    <div class="">
                                        <div class="text-14-60 mb-1">
                                            Glass cabinet with 3 doors and 3 panes. Very fragile door. Wooden handles. Glass cabinet with doors...
                                        </div>
                                        <div class="text-14 mb-5">
                                            2 Bundles, 6 pounds
                                        </div>
                                        <div class="text-14-60">
                                            Carier
                                        </div>
                                        <div class="">
                                            AAA Cooper Transportation
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card> -->
                </v-col>
            </v-row>

        </div>
    </layout>
</template>

<script>
import Vue from 'vue'
import Layout from '@/components/layouts/AccountLayout.vue'
import CardOrder from '@/components/pages/CardOrder'
export default {
    middleware: 'auth',
    components: {
        Layout,
        CardOrder
    },
    data: () => ({
        orders: [],
        // order_load: false,
        quotes: [],
        // quote_load: false,

    }),
    mounted(){
        this.getLoads('orders');
        this.getLoads('quotes');
    },
    methods: {
        async getLoads(type = 'orders', take = 6, skip = 0){
            var uri = process.env.VUE_APP_API + 'loads/v1/' + type + '?take=' + take + '&skip=' + skip;

            await this.axios.get(uri).then(response => {
                this[type] = Object.assign({}, this[type], response.data);
            }).catch();
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
        dateCompare(date1, date2, compare){
            let d1 = new Date(date1)
            let d2 = new Date(date2)

            if(compare == '>'){
                return d1 > d2;
            }
            if(compare == '<'){
                return d1 < d2;
            }
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        }

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.dashboard{
    .top{
        .trackshipping{
            .v-input__slot{
                input::placeholder{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    color: #808080;
                }
                .v-input__append-inner i{
                    color: #808080;

                }
            }
            .v-input__slot::before,.v-input__slot::after{
                bottom: -15px;
            }
            .v-input__slot::before{
                border-color: #CDE0FF!important;
            }
        }
    }
    .main{
        .v-card{
            .top{
                border-bottom: 1px dashed #CDE0FF;

            }
            .content{
                .mw-60{
                    max-width: 60%;
                }
            }
        }
    }
}
</style>
